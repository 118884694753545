import React, { useState, useEffect, useRef } from 'react';
import { JsonRpcProvider, Contract } from 'ethers';
import { CONTRACT_ABI, CONTRACT_ADDRESS } from './constants';
import './GameInfo.css';

const TOTAL_SEATS = 108;

const GameInfo = ({ walletAddress, updateMyEntriesCount }) => {
  const [isGameActive, setIsGameActive] = useState(false);
  const [gameId, setGameId] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [remainingSeats, setRemainingSeats] = useState(TOTAL_SEATS);
  const scrollRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const provider = new JsonRpcProvider('https://polygon-mainnet.g.alchemy.com/v2/whbGRoCzoVk9R8uuZ76JOqDvkIT4SRxz'); // Remplacez 'your-api-key' par votre clé API Alchemy
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);

      try {
        const isActive = await contract.isGameActive();
        setIsGameActive(isActive);

        const launchCount = await contract.getLaunchCount();
        const gameIdNumber = launchCount.toString(); // Convertir en chaîne de caractères
        setGameId(gameIdNumber);

        // Utiliser gameIdNumber - 1 pour obtenir l'ID correct
        const actualGameId = parseInt(gameIdNumber) - 1;

        console.log("fetching participants for game ID:", actualGameId);

        const participantsData = await contract.getParticipants(actualGameId);
        console.log("Participants data:", participantsData);

        // Créer une copie du tableau des participants et inverser l'ordre
        const participantsList = [...participantsData[0]].reverse();
        setParticipants(participantsList);

        const seatsLeft = TOTAL_SEATS - participantsList.length;
        setRemainingSeats(seatsLeft);

        // Mettre à jour le nombre d'entrées de l'utilisateur connecté
        updateMyEntriesCount(participantsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des données du contrat:", error);
      }
    }

    fetchData();
    const interval = setInterval(fetchData, 10000); // Polling toutes les 10 secondes
    return () => clearInterval(interval);
  }, [walletAddress]);

  return (
    <div className="game-info">
      <div className="status-indicator">
        <div className="game-id">
          <p>Rocket ID : {gameId !== null ? gameId - 1 : 'Loading...'}</p>
        </div>
        <div className="countdown">
          <p>COUNTDOWN : {remainingSeats}</p>
        </div>
      </div>
      <div className="participants-list">
        {participants.length > 0 ? (
          <ul className="scrollable-tbody" ref={scrollRef}>
            {participants.map((address, index) => (
              <li key={index} className="participant-row">
                <span className="participant-index">{participants.length - index} :</span>
                <span className="participant-address">{address}</span>
              </li>
            ))}
          </ul>
        ) : (
          <p>No participants yet.</p>
        )}
        <h6>
          Lastest Degen Galaxy Rider 
          <span className={`led ${isGameActive ? 'green' : 'grey'}`}></span>
          <span className={`led ${!isGameActive ? 'red' : 'grey'}`}></span>
        </h6>
      </div>
    </div>
  );
};

export default GameInfo;
