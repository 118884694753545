import React, { useEffect } from 'react';
import './TermsAndConditions.css';


const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="homepage-term">
            <div className="welcome-section-term">
        <div className="left-section-term">
        <div className="terms-container">
            <section className="terms-section">
            <u><h1>Terms and Conditions</h1></u>
            </section>
            
            <section className="terms-section">
                <u><h2>1- Compliance with Local Laws</h2></u>
                <p>Users are responsible for ensuring compliance with the laws of their jurisdiction. FeelG® is not liable for any illegal use of the service by the users. It is essential to understand and abide by the regulations of your country.</p>
            </section>

            <section className="terms-section">
            <u><h2>2- Game Rules</h2></u>
                <p>All game rules are embedded within their respective smart contracts. These contracts are documented and explicit to ensure comprehension by all users. The smart contracts are immutable and govern the mechanics of the games.</p>
            </section>
            
            <section className="terms-section">
            <u><h2>3- No Refunds or Exchanges</h2></u>
                <p>All transactions, including the purchase of entry tickets, are final and non-refundable. As transactions are managed automatically on the blockchain, there is no possibility for cancellation or exchange.</p>
            </section>
            
            <section className="terms-section">
            <u><h2>4- Responsible Gaming</h2></u>
                <p>Users should play responsibly and only spend what they can afford to lose. Please consider your financial situation and play within your means.</p>
            </section>
            
            <section className="terms-section">
            <u><h2>5- Security and Web3</h2></u>
                <p>Users are responsible for practicing proper security measures in the web3 environment. FeelG® cannot be held accountable for any loss or compromise of user data due to inadequate security practices.</p>
            </section>
            
            <section className="terms-section">
            <u><h2>6- Contact and Support</h2></u>
                <p>For any queries or support, FeelG® strives to provide assistance through our Discord channel. We encourage users to reach out for help or clarification.</p>
            </section>
            </div>
        </div>
       
             </div>
        </div>
    );
};

export default TermsAndConditions;
