import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <footer className="footer">
            <div className="left-side">
            <div className="copyright">
                <p>© 2023 FeelG. All rights reserved.</p>
                </div>
                <div className="terms">
                <p><Link to="/terms-and-conditions">Terms and Conditions</Link></p>
                </div>
                         
                </div>
            <div className="right-side">
                <div className='iconf'>
                <a href="https://discord.gg/FyNyJuRr"target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faDiscord} /></a>
                <a href="https://twitter.com/FeelgRocket"target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faXTwitter} /></a>
                <a href="mailto:feelgcontact@gmail.com" title="Email us for collaborations">
            <FontAwesomeIcon icon={faEnvelope} />
        </a>
        </div>
        <div className='mail'><p>Got a burning question or just want to say hi? Ping us above! We might even send you a GIF. 🚀</p></div>
            </div>
        </footer>
    );
};

export default Footer;
