// GameRules.js
import React from 'react';
import { Link } from 'react-router-dom';
import './GameRules.css';

const GameRules = () => {
    return (
        <div className="game-rules">
        <h2>DEGEN GALAXY RIDER Rules</h2>
        <p>Connect your MetaMask wallet on Polygon blockchain to join the adventure and see game details.</p>
        <p>‎ </p>
        <p>. Your ticket to the stars costs just 2.5 POL!</p>
        <p>. Race with 108 slots available - it's a galaxy of opportunities!</p>
        <p>. When all seats are sold, the draw kicks off automatically - no human hand can sway fate here!</p>
        <p>. 36 Winners are drawn using the power of Chainlink oracle.</p>
        <p>‎ </p>
        <p><strong>🌟 1st Prize : 1x 100 POL </strong></p>
        <p><strong>👾 2nd Prize : 5x 10 POL </strong></p>
        <p><strong>🛸 3rd Prize : 10x 5 POL </strong></p>
        <p><strong>🌠 4th Prize : 20x 2.75 POL </strong></p>
        <p>‎ </p>
        
        <p>So gear up, get your seat, and may the cosmos be in your favor !</p>

        <p>Smartcontract : <a href='https://polygonscan.com/address/0x2098c025D38684158dAfe33ba6d0FaFFFa841B89' target="_blank" rel="noopener noreferrer"><strong>0x2098c025D38684158dAfe33ba6d0FaFFFa841B89 </strong></a></p>
        <p>Company share of only 5%</p>
    
  
      </div>
    );
  };
  
  export default GameRules;
