function getTicketPhrase(numSeats) {
    if (numSeats >= 1 && numSeats <= 10) {
      switch (numSeats) {
        case 1: return "Blast off with a lone Cosmoonot - One seat into madness!";
        case 2: return "Two seats in the Degen Galaxy, double the fun!";
        case 3: return "Three's a crowd? More like a cosmic party for three seats!";
        case 4: return "Four seats, four times the Galactic Guru vibes!";
        case 5: return "Five seats of pure cosmic chaos - let's stir up the galaxy!";
        case 6: return "Six seats to superstardom - Illuminati level degen!";
        case 7: return "Seven seats soaring high - lucky number of the cosmos!";
        case 8: return "Eight seats, eight wonders - Galactic Guru style!";
        case 9: return "Nine seats to feelG nirvana - almost a cosmic council!";
        case 10: return "Deca-riders unite! Ten seats in the ultimate Degen Galaxy quest!";
        default: break;
      }
    } else if (numSeats > 10 && numSeats <= 20) {
      return "Galactic Overdrive! Taking " + numSeats + " seats to the stars!";
    } else if (numSeats > 20 && numSeats <= 30) {
      return "A Cosmic Convoy of " + numSeats + " - Riding the Milky Way like bosses!";
    } else if (numSeats > 30 && numSeats <= 40) {
      return numSeats + " Seats in the Cosmic Carnival - A Galactic Spectacle!";
    } else if (numSeats > 40 && numSeats <= 50) {
      return "The Big " + numSeats + "! A Stellar Celebration in the Degen Galaxy!";
    } else if (numSeats > 50 && numSeats <= 60) {
      return "A Celestial Fiesta with " + numSeats + " Seats - Party in the Cosmos!";
    } else if (numSeats > 60 && numSeats <= 70) {
      return "Orbiting Olympus with " + numSeats + " Seats - Gods of the Galaxy!";
    } else if (numSeats > 70 && numSeats <= 80) {
      return "The Magnificent " + numSeats + "! A Symphony of Stars!";
    } else if (numSeats > 80 && numSeats <= 90) {
      return "Galactic Grandeur with " + numSeats + " Seats - Supreme Space Saga!";
    } else if (numSeats > 90 && numSeats <= 100) {
      return "The Ultimate " + numSeats + " - Legends of the Degen Galaxy!";
    } else {
      return "Select your seats to join the Degen Galaxy Adventure!";
    }
  }
export default getTicketPhrase;