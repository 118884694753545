import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import './Spinner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CONTRACT_ABI, CONTRACT_ADDRESS } from './constants'; // Importer l'ABI et l'adresse du contrat

const phrases = [
  "🚀 Hold tight! The drawing process is starting! 🌕",
  "🌠 Buckle up! We're calculating the luckiest stars in the galaxy! 🌌",
  "👽 Contacting aliens for extra luck... Any moment now! 🛸",
  "🪐 Doing a quick moonwalk before announcing the lucky stars! 🌜",
  "🌕 Full moon magic at work! Preparing to unveil the luckiest astronauts! 🌟"
];

const steps = [
  "Drawing random numbers...",
  "Paying the winners...",
  "Process complete!"
];

const Spinner = ({ onClose }) => {
  const [message, setMessage] = useState(phrases[0]);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const provider = new ethers.JsonRpcProvider('https://polygon-mainnet.g.alchemy.com/v2/whbGRoCzoVk9R8uuZ76JOqDvkIT4SRxz');
    const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);

    // Événement pour la première étape : Fermeture du lancement
    const handleLaunchpadClosed = (launchingclosed) => {
      if (launchingclosed && currentStep === 0) {
        setCurrentStep(1);
      }
    };

    // Événement pour la deuxième étape : Tous les nombres aléatoires générés
    const handleTousLesNombresAleatoiresGeneres = () => {
      if (currentStep === 1) {
        setCurrentStep(2);
        setTimeout(() => {
          setCurrentStep(3);
        }, 5000); // Passer à l'étape 3 après 5 secondes
      }
    };

    contract.on('LaunchpadClosed', handleLaunchpadClosed);
    contract.on('TousLesNombresAleatoiresGeneres', handleTousLesNombresAleatoiresGeneres);

    return () => {
      contract.off('LaunchpadClosed', handleLaunchpadClosed);
      contract.off('TousLesNombresAleatoiresGeneres', handleTousLesNombresAleatoiresGeneres);
    };
  }, [currentStep]);

  useEffect(() => {
    let messageIndex = 0;
    let intervalId;

    if (currentStep < 3) {
      setMessage(phrases[messageIndex % phrases.length]);

      intervalId = setInterval(() => {
        messageIndex++;
        setMessage(phrases[messageIndex % phrases.length]);
      }, 8000);
    } else {
      setMessage("🎉 CONGRATULATIONS TO THE LUCKY WINNERS! 🚀");
    }

    return () => clearInterval(intervalId); // Nettoyage de l'intervalle
  }, [currentStep]);

  return (
    <div className="spinner-container">
      {currentStep === 3 && (
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
      <div className="progress-bar">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`progress-step ${index < currentStep ? 'completed' : index === currentStep ? 'in-progress' : ''}`}
          >
            <span>
              {index < currentStep ? (
                <FontAwesomeIcon icon={faCheckCircle} />
              ) : index === currentStep ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                index + 1
              )}
            </span>
            <p>{step}</p>
          </div>
        ))}
      </div>
      {currentStep < 3 ? (
        <p>{message}</p>
      ) : (
        <div className="congratulations">
          <p className="congratulations-message">🎉 CONGRATULATIONS TO THE LUCKY WINNERS! 🚀</p>
        </div>
      )}
    </div>
  );
};

export default Spinner;