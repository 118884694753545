import React, { useState, useEffect } from 'react';
import './LotteryPage.css';
import { Link } from 'react-router-dom';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { CONTRACT_ABI, CONTRACT_ADDRESS } from './constants';
import GameInfo from './GameInfo';
import GameRules from './GameRules';
import getTicketPhrase from './ticketPhrases';
import Spinner from './Spinner';

function LotteryPage() {
  const [account, setAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [ticketCount, setTicketCount] = useState(1); // État pour le nombre de tickets
  const [myEntriesCount, setMyEntriesCount] = useState(0); // État pour le nombre de participations
  const [showRules, setShowRules] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const init = async () => {
      const provider = new ethers.JsonRpcProvider('https://polygon-mainnet.g.alchemy.com/v2/whbGRoCzoVk9R8uuZ76JOqDvkIT4SRxz'); // Utiliser le fournisseur JSON-RPC Alchemy
      setProvider(provider);
      const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);

      contract.on('LaunchpadClosed', (launchingclosed) => {
        setShowSpinner(launchingclosed);
      });

      contract.on('TousLesNombresAleatoiresGeneres', () => {
        // Logique supplémentaire si nécessaire
      });

      // Supprimer l'écoute de GameToggled
    };

    init();
  }, []);

  const toggleRules = () => {
    setShowRules(!showRules);
  };

  const connectWallet = async () => {
    if (!account) {
      try {
        const web3Modal = new Web3Modal();
        const instance = await web3Modal.connect();
        const web3Provider = new ethers.BrowserProvider(instance);

        const signer = await web3Provider.getSigner();
        const userAddress = await signer.getAddress();

        setProvider(web3Provider);
        setAccount(userAddress);
      } catch (error) {
        console.error("Failed to connect wallet:", error);
      }
    } else {
      setProvider(null);
      setAccount(null);
    }
  };

  const updateMyEntriesCount = (participantsList) => {
    if (Array.isArray(participantsList[0])) {
      const myEntries = participantsList[0].filter(address => address.toLowerCase() === account.toLowerCase());
      setMyEntriesCount(myEntries.length);
    } else {
      console.error('Expected participantsList[0] to be an array, received:', participantsList[0]);
      setMyEntriesCount(0); // Assumer qu'il n'y a pas d'entrées si la structure n'est pas celle attendue
    }
  };

  const enterLottery = async () => {
    if (!provider) {
      console.error("Please connect your wallet first.");
      return;
    }

    try {
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
      const tx = await contract.enter(ticketCount, { value: ethers.parseUnits((ticketCount * 2.5).toString(), "ether") });
      await tx.wait();
      console.log("Transaction successful:", tx);
      // Mise à jour du nombre de participations après l'achat de tickets
      fetchParticipants();
    } catch (error) {
      console.error("Failed to enter the lottery:", error);
    }
  };

  const fetchParticipants = async () => {
    if (!provider) return;

    const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);

    try {
      const launchCount = await contract.getLaunchCount();
      const gameIdNumber = launchCount.toString(); // Convertir en chaîne de caractères
      const actualGameId = parseInt(gameIdNumber) - 1;
      const participantsData = await contract.getParticipants(actualGameId);

      // Mettre à jour le nombre de participations de l'utilisateur connecté
      updateMyEntriesCount(participantsData);
    } catch (error) {
      console.error("Erreur lors de la récupération des données du contrat:", error);
    }
  };

  useEffect(() => {
    if (account && provider) {
      fetchParticipants();
    }
  }, [account, provider]);

  const increaseTicketCount = () => {
    setTicketCount(ticketCount + 1);
  };

  const decreaseTicketCount = () => {
    if (ticketCount > 1) {
      setTicketCount(ticketCount - 1);
    }
  };

  return (
    <div className="homepage">
      <div className='TOPOFTOP'>
        <h2>Degen Galaxy Rider</h2>
      </div>
      <div className='TOPP'>
        <div className='TOPPG'>
          <div className='TOPPG1'>
            <div><GameInfo walletAddress={account} updateMyEntriesCount={updateMyEntriesCount} /></div>
            <div className="subtitle">‎ </div>
            <p className='LatD'>‎ </p>
            <div className="status-indicator">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className='TOPPD'>
          <div className='TOPPD1'>
            <button className='TB' onClick={connectWallet}>
              {account ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Wallet Connect'}
            </button>
          </div>
          <div className='TOPPD2'>
            <div className="input-container">
              <button className='TB TB-plus-minus' onClick={decreaseTicketCount}>-</button>
              <span className='calc'>{ticketCount}</span>
              <button className='TB TB-plus-minus' onClick={increaseTicketCount}>+</button>
            </div>
            <div className="input-container">
              <span className='calc'>for {ticketCount * 2.5} POL</span>
            </div>
            <button className={`TB2 ${showSpinner ? 'disabled-button' : ''}`} onClick={enterLottery} disabled={showSpinner}>
              Enter
            </button>
            <p className='Tphrase'>{getTicketPhrase(ticketCount)}</p>
          </div>
          <div className='TOPPD2'>
            <div>
              <p>My Seats: {myEntriesCount}</p>
            </div>
          </div>
          <div className='Linkbox'>
          <Link to="/LaunchDetailsPage">
              <button className='TB TB-connected'>Previous Draw</button>
            </Link>
            <button className='TB TB-connected' onClick={toggleRules}>The Rules</button>
          </div>
        </div>
      </div>
      <div className="rules-container">
        {showRules && <GameRules />}
        <div className='advertising2'><p>Smartcontract - <a href='https://polygonscan.com/address/0x2098c025D38684158dAfe33ba6d0FaFFFa841B89' target="_blank" rel="noopener noreferrer">0x2098c025D38684158dAfe33ba6d0FaFFFa841B89 -</a></p></div>
      </div>
      <div className='advertising'>
        <p>Purchasing a ticket implies your acceptance of our Terms and Conditions <Link to="/terms-and-conditions">( Click here )</Link> of sale. Lottery games are meant for entertainment, not financial strategy. Only play with crypto you're prepared to lose and seek help if you feel you're developing a gambling problem. Above all, take care of yourself.</p>
      </div>
      {showSpinner && <Spinner onClose={() => setShowSpinner(false)} />} {/* Spinner avec fonction de fermeture */}
    </div>
  );
}

export default LotteryPage;