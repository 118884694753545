import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Welcome from './components/Welcome';
import TermsAndConditions from './components/TermsAndConditions';
import Header from './components/Header';
import Footer from './components/Footer';
import LotteryPage from './components/LotteryPage';
import LotteryPageG2 from './components/LotteryPageG2';
import LotteryPageG3 from './components/LotteryPageG3';
import LaunchDetailsPage from './components/LaunchDetailsPage';
import './App.css';


function App() {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    useEffect(() => {
        if(isModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        
        // Remise à l'état initial lors du démontage du composant
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isModalOpen]);

    const handleModalClose = () => {
        if(isCheckboxChecked) {
            setIsModalOpen(false);
        } else {
            alert("You must confirm that you comply with the rules of your country of residence to access the site.");
        }
    };

    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };

    return (
         
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Welcome />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/Degen Galaxy Rider" element={<LotteryPage />} />
                <Route path="/LaunchDetailsPage" element={<LaunchDetailsPage />} />
                <Route path="/Stellar Illuminati" element={<LotteryPageG2 />} />
                <Route path="/Ultra Quest" element={<LotteryPageG3 />} />
            </Routes>
            <Footer />
            {isModalOpen && (
                <div className="popup-container">
                    <div className="popup-content">
                        <h2>Disclaimer</h2>
                        <p>‎ </p>
                        <p>By accessing this website, you confirm that you are complying with the laws and regulations of your country of residence.</p>
                        <p>‎ </p>
                        <input 
                            type="checkbox" 
                            id="verification" 
                            name="verification" 
                            onChange={handleCheckboxChange} 
                        />
                        <label htmlFor="verification">‎ ‎  I confirm that I am abiding by my country of residence's laws and regulations.</label><br /><br />
                        <button className="confirm-button" disabled={!isCheckboxChecked} onClick={handleModalClose}>Confirm</button>
                    </div>
                </div>
            )}
        </Router>
        
    );
}

export default App;
