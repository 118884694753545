import React, { useState, useEffect } from 'react';
import './LaunchDetailsPage.css';
import { Link } from 'react-router-dom';
import { JsonRpcProvider, Contract, formatUnits } from 'ethers';
import { CONTRACT_ABI, CONTRACT_ADDRESS } from './constants';

function LaunchDetailsPage() {
  const [gameData, setGameData] = useState([]);
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [launchCount, setLaunchCount] = useState(0);

  useEffect(() => {
    async function fetchInitialData() {
      const provider = new JsonRpcProvider('https://polygon-mainnet.g.alchemy.com/v2/whbGRoCzoVk9R8uuZ76JOqDvkIT4SRxz'); // Remplacez 'your-api-key' par votre clé API Alchemy
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);

      try {
        const launchCount = await contract.getLaunchCount();
        setLaunchCount(parseInt(launchCount.toString()));
        const initialGameId = parseInt(launchCount.toString()) - 1;
        setSelectedGameId(initialGameId); // Sélectionner le dernier jeu par défaut
        fetchData(initialGameId, contract); // Fetch data for the initial game ID
      } catch (error) {
        console.error("Erreur lors de la récupération des données initiales du contrat:", error);
      }
    }

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedGameId !== null) {
      const provider = new JsonRpcProvider('https://polygon-mainnet.g.alchemy.com/v2/whbGRoCzoVk9R8uuZ76JOqDvkIT4SRxz'); // Remplacez 'your-api-key' par votre clé API Alchemy
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, provider);
      fetchData(selectedGameId, contract);
    }
  }, [selectedGameId]);

  const fetchData = async (gameId, contract) => {
    try {
      const participantsData = await contract.getParticipants(gameId);
      const formattedData = participantsData[0].map((address, index) => ({
        address,
        gains: formatUnits(participantsData[1][index].toString(), 'ether'), // Conversion des gains en MATIC
        drawNumber: participantsData[2][index].toString()
      }));
      setGameData(formattedData);
    } catch (error) {
      console.error("Erreur lors de la récupération des données du contrat:", error);
    }
  };

  const handleGameIdChange = (event) => {
    setSelectedGameId(parseInt(event.target.value));
  };

  return (
    <div className="homepage">
      <div className="launch-details-page">
        <h1>Degen Galaxy Rider Data</h1>
        <p>Select a Game ID to view detailed game data.</p>

        <div className="input-container">
          <Link to="/Degen Galaxy Rider">
            <button className='TB'>Back to Game</button>
          </Link>
        </div>

        <div className="input-container">
          <label htmlFor="gameIdSelector">Game ID: </label>
          <select id="gameIdSelector" className="game-id-selector" value={selectedGameId} onChange={handleGameIdChange}>
            {[...Array(launchCount).keys()].map(id => (
              <option key={id} value={id}>
                {id}
              </option>
            ))}
          </select>
        </div>

        <div className="table-responsive">
          <table className="launch-details-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Adresse</th>
                <th>GAINS (POL)</th>
                <th>Draw Number</th>
              </tr>
            </thead>
            <tbody>
              {gameData.map((data, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{data.address}</td>
                  <td>{data.gains}</td>
                  <td>{data.drawNumber}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>Select a Game ID to view detailed game data.</p>
        </div>
      </div>
    </div>
  );
}

export default LaunchDetailsPage;
