import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faShareSquare, faHeart, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import clickSound from '../assets/images/voicefeelg.mp3'
import './Header.css';

const Header = () => {
    const playSound = () => {
        const audio = new Audio(clickSound);
        audio.play();};
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef();  // Créez une référence pour le menu

    // Gestionnaire pour fermer le menu si le clic est à l'extérieur
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);  // Ferme le menu si le clic est à l'extérieur du menu
            }
        };

        // Ajoutez l'écouteur d'événements lorsque le menu est ouvert
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Nettoyage de l'écouteur d'événements
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const closeMenu = () => {
        setIsOpen(false);
    };

    

    return (
        <header className="header">
            <div>
                <Link to="/" className="brand-name-link">
                    <span className="brand-name" onClick={playSound}><span className="blue">Feel</span><span className="white">G®</span></span>
                </Link>
            </div>
            <div className='btn-part'>
                {isOpen && (
                    <ul className="dropdown-menu" ref={menuRef}>
                        <Link to="/Degen Galaxy Rider" onClick={closeMenu}><li className="tap-area">🛰️ Degen Galaxy Rider</li></Link>
                        <Link to="/Stellar Illuminati" onClick={closeMenu}><li className="mixed tap-area">🛸 Stellar Illuminati</li></Link>
                        <Link to="/Ultra Quest" onClick={closeMenu}><li className="mixed tap-area">🚀 Ultra Quest</li></Link>
                        <Link to="/" ><li className="disabled tap-area"><FontAwesomeIcon icon={faTrashAlt} /> Red Button</li></Link>
                    </ul>
                )}
                <button className="menu-button" onClick={() => setIsOpen(!isOpen)}><FontAwesomeIcon icon={faBars} /></button>
            </div>
        </header>
    );
};

export default Header;

